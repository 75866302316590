import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';

// export const HomeOuterWrapper = styled.section`
//   overflow: hidden;
//   width: 100%;
// `;
export const HomeWrapper = styled.div<{
  readonly isSelectorsHidden?: boolean;
}>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  padding-top: 50px;
  position: relative;
  margin-bottom: 10vw;
  ${media.max.sm`
    padding-top: 40px;
    min-height: ${(isSelectorsHidden) => (isSelectorsHidden ? 350 : 420)}px;
    margin-bottom: ${60 + 30}px;
  `}
`;
export const HomeInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const HeroContent = styled.div`
  width: 100%;
  max-width: 570px;
  ${media.md`
    min-height: 431px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
  ${media.max.md`
    max-width: none;
  `}
`;
export const HomeHeroImg = styled.img`
  display: block;
  object-fit: contain;
  pointer-events: none;
  ${media.max.lg`
    position: absolute;
    right: 0;
    max-width: 43%;
    `}
  ${media.max.md`
    max-width: 40% !important;
    right: 2%;
    top: 0;
`}
  ${media.max.sm`
    display: none;
  `}
`;
export const HeroStripWrapper = styled.div`
  position: absolute;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: scaleX(1) /*rtl: scaleX(-1) */;
  ${media.max.sm`
      bottom: -18%;
      svg {
        width: calc(100% + 20px);
        margin-left: -10px  ;
        min-width: 394px;
        min-height: 24vw;
      }
  `}
`;
export const HeroTitle = styled.h1`
  font-size: 4rem !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
  color: ${({ theme }) => theme.colors.primaryPurple} !important;
  max-width: 450px;
  margin: 0 0 40px !important;
  ${media.max.sm`
    font-size: 2.4rem !important;
    margin: 0 0 30px !important;
    text-align: center;
    max-width: none;
  `}
`;
export const HeroActionsWrapper = styled.div``;
export const VisitTypesWrapper = styled.div`
  display: flex;
  align-items: center;
  /* gap: 24px; */
  margin-bottom: 82px;
  ${media.max.sm`
    justify-content: center;
    margin-bottom: 72px;
  `}
`;
export const VisitType = styled.button<{
  readonly isSelected?: boolean;
}>`
  appearance: none !important;
  border: none !important;
  outline: none !important;
  padding: 12px 15px 6px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: 7.5px !important;
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white : theme.colors.primaryPurple)} !important;
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.primaryPurple : 'transparent')} !important;
  width: 100px;
  height: 62px;
  white-space: nowrap;
  border-radius: 5px;
  transition: 0.2s ease background-color, 0.2s ease color;
  margin-right: 24px !important;
  &:last-of-type {
    margin-right: 0 !important;
  }
`;
export const FormDropdownWrapper = styled.div<{ readonly isSelectorsHidden?: boolean }>`
  position: relative;
  width: 100%;
  ${({ isSelectorsHidden }) =>
    isSelectorsHidden &&
    css`
      margin-top: 42px;
      ${media.max.md`
        margin-top: 72px;
      `}
    `}
`;
export const SpecialtyWrapper = styled.div`
  display: flex;
  /* gap: 15px; */
  width: 100%;
  ${media.max.sm`
    flex-direction: column;
    gap: 0;
  `}
`;
export const HeroCTA = styled.button`
  appearance: none !important;
  border: none !important;
  outline: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: ${({ theme }) => theme.colors.white} !important;
  background: ${({ theme }) => theme.colors.primaryPink};
  border-radius: 5px;
  width: 100%;
  font-size: ${({ theme }) => theme.fontsizes.body16} !important;
  font-weight: ${({ theme }) => theme.fontWeight.font700} !important;
  min-width: 200px;
  max-width: 200px;
  height: 67px;
  z-index: 1;
  margin-left: 15px !important;
  ${media.max.sm`
    min-width: 100%;
    max-width: 100%;
    margin-top: 15px !important;
    margin-left: 0 !important;
  `}
`;
