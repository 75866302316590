import useTranslation from 'intl/useTranslation';
import { Dispatch, FC, SetStateAction } from 'react';
import { HomePageFilterBody, HomePageFilterContainer } from './HomePageFilters';

interface HomePageFilterProps {
  filters: any[];
  callBack?: Dispatch<SetStateAction<string>>;
  activeFilter: string;
}

const HomePageFilters: FC<HomePageFilterProps> = ({ filters, callBack, activeFilter }: HomePageFilterProps) => {
  const { t } = useTranslation();
  return (
    <HomePageFilterContainer>
      {filters.map((filter) => {
        return (
          <HomePageFilterBody active={activeFilter === filter} onClick={() => callBack(filter)}>
            {filter}
          </HomePageFilterBody>
        );
      })}
    </HomePageFilterContainer>
  );
};

export default HomePageFilters;
