import useDidMountEffect from "@/hooks/useDidMountEffect";
import useTranslation from "@/intl/useTranslation";
import {
  autoUpdate,
  ContextData,
  flip,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react-dom-interactions";
import debounce from "lodash/debounce";
import React, {
  createContext,
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMediaQuery } from "react-responsive";
import { useIsomorphicLayoutEffect } from "react-use";
import DropdownArrowIcon from "../icons/DropdownArrowIcon";
import {
  CommonSelectButton,
  CommonSelectButtonData,
  CommonSelectTitle,
  CommonSelectValue,
  DropdownWrapper,
  StyledOption,
  StyledUl,
} from "./CommonDropdown.styled";
import SearchInput from "./SearchInput";

interface DropdownOption {
  name: string;
  name_en: string;
  value: string;
}

interface SelectContext {
  activeIndex: number | null;
  setActiveIndex: (index: number | null) => void;
  value?: string;
  setValue: Dispatch<SetStateAction<string>>;
  listRef: React.MutableRefObject<Array<HTMLLIElement | null>>;
  setOpen: (open: boolean) => void;
  getItemProps: (userProps?: React.HTMLProps<HTMLElement>) => any;
  dataRef: ContextData;
  afterSelectCallback: (optionValue: string) => void;
}
const TheSelectContext = createContext({} as SelectContext);

export interface CountryValue {
  city?: string;
  area?: string;
}

interface Props {
  options: DropdownOption[];
  value?: string;
  setValue: Dispatch<SetStateAction<string>>;
  placeholder: string;
  searchInputPlaceholder: string;
  title: string;
  selectAllOption?: DropdownOption;
  isError?: boolean;
  toggleOpenCallback?: (opened: boolean) => void;
  searchInputCallback?: (searchTerm: string) => void;
  startSearchAnalytics?: (searchTerm: string) => void;
  label?: string;
}

export const TheOption: React.FC<{
  optionLabel: string;
  optionValue: string;
  index: number;
  label?: string;
}> = memo(({ index = 0, optionLabel, optionValue, label }) => {
  const { listRef, setActiveIndex, afterSelectCallback, value, setValue, activeIndex, getItemProps, dataRef } =
    useContext(TheSelectContext);

  const handleSelect = () => {
    setValue(optionValue);
    setActiveIndex(null);
    afterSelectCallback(optionValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSelect();
    }

    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === " " && !dataRef.current.typing) {
      handleSelect();
    }
  };

  return (
    <StyledOption
      role="option"
      ref={(node) => (listRef.current[index] = node)}
      tabIndex={activeIndex === index ? 0 : 1}
      aria-selected={activeIndex === index}
      data-selected={value === optionValue}
      isSelected={value === optionValue}
      data-cy={(label ? label + "-" : "") + optionValue}
      {...getItemProps({
        onClick: handleSelect,
        onKeyDown: handleKeyDown,
        onKeyUp: handleKeyUp,
      })}
    >
      {optionLabel}
    </StyledOption>
  );
});

export default function CommonDropdown({
  options,
  value: originalValue,
  setValue: setOriginalValue,
  placeholder,
  selectAllOption,
  title,
  searchInputPlaceholder,
  isError,
  toggleOpenCallback,
  searchInputCallback,
  startSearchAnalytics,
  label,
}: Props) {
  const { locale, t } = useTranslation();
  const isDesktop = useMediaQuery({
    minWidth: 768,
  });
  const [value, setValue] = useState<string | null>(originalValue);
  const data = useMemo<DropdownOption[]>(() => (selectAllOption ? [selectAllOption, ...options] : options), []);

  const listItemsRef = useRef<Array<HTMLLIElement | null>>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [pointer, setPointer] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const searchInputRef = useRef(null);

  if (!open && pointer) {
    setPointer(false);
    setSearchTerm("");
  }

  const [customListHeight, setCustomListHeight] = useState(null);
  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: () => {
      if (toggleOpenCallback) toggleOpenCallback(!open);
      setOpen((prev) => !prev);
    },
    whileElementsMounted: autoUpdate,
    placement: "bottom-start",
    middleware: [
      offset(5),
      shift(),
      flip(),
      size({
        apply({ availableHeight, elements }) {
          if (availableHeight <= 400) {
            setCustomListHeight(availableHeight - 10);
          } else {
            setCustomListHeight(null);
          }
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
    ],
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context),
    useRole(context, { role: "listbox" }),
    useDismiss(context),
    useListNavigation(context, {
      listRef: listItemsRef,
      activeIndex,
      onNavigate: setActiveIndex,
    }),
  ]);

  const selectedData = useMemo(() => {
    let theSearchTerm = searchTerm.toLocaleLowerCase();

    if (searchTerm) {
      let options = data.filter(
        (op) =>
          op.name_en.toLocaleLowerCase().includes(theSearchTerm) ||
          (op.name !== null && op.name.toLowerCase().includes(theSearchTerm))
      );
      return options.length ? options : data;
    } else {
      return data;
    }
  }, [searchTerm]);

  // Scroll the active or selected item into view when in `controlledScrolling`
  // mode (i.e. arrow key nav).
  useIsomorphicLayoutEffect(() => {
    if (open && activeIndex != null && !pointer) {
      requestAnimationFrame(() => {
        listItemsRef.current[activeIndex]?.scrollIntoView({
          block: "nearest",
        });
      });
    }
  }, [open, activeIndex, pointer]);

  useEffect(() => {
    if (open && searchInputRef?.current && isDesktop) {
      setTimeout(() => {
        searchInputRef?.current?.focus();
      }, 300);
    }
  }, [open]);

  const afterSelectCallback = (optionValue: string) => {
    setOpen(false);
  };

  const getLabelName = () => {
    if (typeof value === "string") {
      return data.find((op) => op.value === value).name;
    } else {
      return placeholder;
    }
  };

  useDidMountEffect(() => {
    setOriginalValue(value);
  }, [value]);

  const _debouncedSearchInputCallback = useMemo(
    () => (searchInputCallback ? debounce(searchInputCallback, 500) : null),
    []
  );

  return (
    <TheSelectContext.Provider
      value={{
        activeIndex,
        setActiveIndex,
        value,
        afterSelectCallback,
        setValue,
        listRef: listItemsRef,
        setOpen,
        getItemProps,
        dataRef: context.dataRef,
      }}
    >
      <CommonSelectButton
        data-cy={label + "-trigger"}
        {...getReferenceProps({
          ref: reference,
          style: open
            ? {
                zIndex: 11,
              }
            : {},
        })}
        isError={isError}
      >
        <CommonSelectButtonData>
          <CommonSelectTitle>{title}</CommonSelectTitle>
          <CommonSelectValue isOptionSelected={typeof value === "string"}>{getLabelName()}</CommonSelectValue>
        </CommonSelectButtonData>
        <DropdownArrowIcon />
      </CommonSelectButton>
      {open && (
        <FloatingPortal>
          <FloatingOverlay
            lockScroll
            style={{
              background: "rgba(0, 0, 0, 0.4)",
              zIndex: 10,
            }}
          >
            <FloatingFocusManager
              context={context}
              initialFocus={value ? data.findIndex((op) => op.value === value) + 1 : undefined}
            >
              <DropdownWrapper
                dir={locale === "ar" ? "rtl" : "ltr"}
                customListHeight={customListHeight}
                {...getFloatingProps({
                  ref: floating,
                  style: {
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                  },
                  onPointerMove() {
                    setPointer(true);
                  },
                  onKeyDown(event) {
                    setPointer(false);
                    if (event.key === "Tab") {
                      setOpen(false);
                    }
                  },
                })}
              >
                <SearchInput
                  label={label + "-dropdown-search-input"}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  placeholder={searchInputPlaceholder}
                  ref={searchInputRef}
                  onSearchCallback={_debouncedSearchInputCallback}
                  startSearchAnalytics={startSearchAnalytics}
                />
                <StyledUl role="group" aria-labelledby={`floating-ui-select-${title}`}>
                  {selectedData.map((option, index) => (
                    <TheOption
                      key={option.value}
                      index={index}
                      optionLabel={option.name}
                      optionValue={option.value}
                      label={label}
                    />
                  ))}
                </StyledUl>
              </DropdownWrapper>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </TheSelectContext.Provider>
  );
}
