type Props = {
  fill?: string;
};

export default function VideoIcon({ fill = '#2A3390' }: Props) {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4211 12.7373C13.118 13.0791 12.7432 13.25 12.2967 13.25H1.72249C1.27592 13.25 0.877193 13.0791 0.526316 12.7373C0.175439 12.3955 0 11.9967 0 11.541V2.36133C0 1.9056 0.167464 1.52311 0.502392 1.21387C0.837321 0.904622 1.24402 0.75 1.72249 0.75H12.2967C12.7432 0.75 13.118 0.904622 13.4211 1.21387C13.7241 1.52311 13.8756 1.9056 13.8756 2.36133V11.541C13.8756 11.9967 13.7241 12.3955 13.4211 12.7373ZM19.9998 2.31265V11.6877L15.3108 9.09977V4.90055L19.9998 2.31265Z"
        fill={fill}
      />
    </svg>
  );
}
