import ContentLoader from 'react-content-loader';

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={320}
    height={350}
    viewBox="0 0 320 350"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="12" y="144" rx="0" ry="0" width="201" height="13" />
    <rect x="12" y="124" rx="0" ry="0" width="103" height="13" />
    <rect x="12" y="190" rx="0" ry="0" width="297" height="18" />
    <rect x="12" y="284" rx="0" ry="0" width="297" height="55" />
    <rect x="12" y="218" rx="0" ry="0" width="297" height="18" />
    <rect x="12" y="242" rx="0" ry="0" width="297" height="18" />
    <rect x="12" y="8" rx="0" ry="0" width="290" height="104" />
  </ContentLoader>
);

export default MyLoader;
