type Props = {
  fill?: string;
};

export default function PinIcon({ fill = "#282828" }: Props) {
  return (
    <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 15.4992C4.50759 14.6014 3.58772 13.6171 2.75 12.5567C1.49286 10.9642 6.94306e-07 8.59252 6.94306e-07 6.33252C-0.00111358 3.97214 1.33905 1.84368 3.39513 0.940352C5.4512 0.0370244 7.81791 0.536888 9.39086 2.20669C10.4252 3.29886 11.0046 4.78451 11 6.33252C11 8.59252 9.50714 10.9642 8.25 12.5567C7.41228 13.6171 6.49241 14.6014 5.5 15.4992ZM5.5 2.16585C3.33139 2.16861 1.57403 4.03248 1.57143 6.33252C1.57143 7.30419 1.9855 8.98669 3.95607 11.4875C4.44175 12.1026 4.95708 12.6906 5.5 13.2492C6.04296 12.6912 6.55855 12.1041 7.04472 11.49C9.0145 8.98585 9.42857 7.30335 9.42857 6.33252C9.42597 4.03248 7.66861 2.16861 5.5 2.16585ZM5.5 8.83252C4.19819 8.83252 3.14286 7.71323 3.14286 6.33252C3.14286 4.95181 4.19819 3.83252 5.5 3.83252C6.80182 3.83252 7.85714 4.95181 7.85714 6.33252C7.85714 6.99556 7.6088 7.63145 7.16675 8.10029C6.7247 8.56913 6.12515 8.83252 5.5 8.83252Z"
        fill={fill}
      />
    </svg>
  );
}
