import NextImage from 'next/image';
import Address from 'public/images/HomePageIcons/AddressIcon.svg';
import Area from 'public/images/HomePageIcons/AreaIcon.svg';
import Speciality from 'public/images/HomePageIcons/SpecialityIcon.svg';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

export const CardGrouping = styled.div<{ noMargin?: boolean }>`
  margin: ${({ noMargin }) => (noMargin ? '0px' : '10px 0 0 0')};
  /* max width should always be the width of the image above to align correctly */
  width: 100%;
  display: block !important;
`;

export const Image = styled(NextImage)`
  border-radius: 10px;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 127px;
  margin: 0 auto 10px;
`;

export const ClinicInformationWrapper = styled.div`
  display: flex;
  width: 100%;
  &:last-of-type {
    margin-bottom: 8px;
  }
`;

export const ClinicName = styled.h3`
  font-weight: 700 !important;
  display: inline-block;
  font-size: 14px !important;
  line-height: 20px;
  margin: 8px 0 4px 0 !important;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
`;

export const InformationDescription = styled.span`
  display: inline-block !important;
  margin: 2px 0 !important;
  line-height: 17px;
  font-size: 12px !important;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  min-height: 15px;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.textGray};
  strong {
    color: ${({ theme }) => theme.colors.textDark};
  }
`;

export const ProfileLink = styled.a`
  border: 1px solid ${({ theme }) => theme.colors.primaryGreen};
  color: ${({ theme }) => theme.colors.primaryGreen} !important;
  background: ${({ theme }) => theme.colors.white} !important;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4.25px;
  height: 46.71px;
  border-radius: 4.24638px;
  margin: auto 0 0 0;
  font-weight: 400 !important;
  font-size: 13.5884px !important;
  line-height: 20px !important;
  cursor: pointer;
  justify-self: end !important;
  &:hover {
    text-decoration: none !important;
    color: #fff !important;
    background: #48bfc0 !important;
    transition: all 0.3s;
  }

  ${media.max.sm`
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  `}
`;

export const CardLink = styled.a`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 0 0 0;
  color: ${({ theme }) => theme.colors.textDark} !important;
  text-decoration: none !important;
  padding: 10px !important;
  min-width: 320px;
  width: 320px;
  background: ${({ theme }) => theme.colors.white} !important;
  box-shadow: 0px 3px 6px rgba(85, 85, 85, 0.15);
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #e7e9ef;
`;

export const SpecialityIcon = styled(Speciality)`
  display: inline-block;
  margin: auto 0 -4px 0;
  width: 13px;
  height: 15px;
`;

export const AreaIcon = styled(Area)`
  display: inline-block;
  margin: auto 0 -4px 0;
  width: 13px;
  height: 15px;
`;

export const AddressIcon = styled(Address)`
  display: inline-block;
  margin: auto 0 -4px 0;
  width: 13px;
  height: 15px;
`;
