import styled from 'styled-components';

export const StyledClinicIcon = styled.svg`
  width: 21px;
  height: 21px;
  display: inline-block;
  fill: currentColor;
  &:hover {
    fill: currentColor;
  }
`;
