import useTranslation from '@/intl/useTranslation';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { DiscountCategoriesList, DiscountDataResponse } from 'types/Discount';
import { HomePageData } from 'types/Home';
import HeroActions from './HeroActions';
import { HeroContent, HeroStripWrapper, HeroTitle, HomeHeroImg, HomeInner, HomeWrapper } from './HomeHero.styled';
import HomeHeroStrip from './icons/HomeHeroStrip';

type Props = {
  data: HomePageData;
  discounts: DiscountDataResponse;
  discountCategories: DiscountCategoriesList<string>;
};

export default function HomeHero({ data, discounts, discountCategories }: Props) {
  const { t } = useTranslation();
  const { locale_name } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const [isSelectorsHidden, setIsSelectorsHidden] = useState(false);

  return (
    <HomeWrapper isSelectorsHidden={isSelectorsHidden}>
      <Container>
        <Row>
          <Col col={12}>
            <HomeInner>
              <HeroContent>
                <HeroTitle>
                  {t('Book Appointments with the Best Doctors')} {locale_name}
                </HeroTitle>
                <HeroActions
                  data={data}
                  discounts={discounts}
                  discountCategories={discountCategories}
                  isSelectorsHidden={isSelectorsHidden}
                  setIsSelectorsHidden={setIsSelectorsHidden}
                />
              </HeroContent>
              <HomeHeroImg src="/next-images/home-hero.svg" alt="Hero Area Illustration" />
            </HomeInner>
          </Col>
        </Row>
      </Container>
      <HeroStripWrapper>
        <HomeHeroStrip />
      </HeroStripWrapper>
    </HomeWrapper>
  );
}
