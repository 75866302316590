type Props = {
  fill?: string;
};

export default function DropdownArrowIcon({ fill = '#2A3390' }: Props) {
  return (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1.33657L6 7L12 1.33657L10.584 0L6 4.34951L1.392 0L0 1.33657Z" fill={fill} />
    </svg>
  );
}
