import { localize } from '@/utils';
import Axios from 'lib/Axios';
import { DiscountData, DiscountDataResponse, DiscountListing, DiscountListingResponse } from 'types/Discount';

export const _GetDiscountsData = ({ client_side_country, locale }: DiscountData) =>
  Axios.get<DiscountDataResponse>(localize('bookings/discount_categories/', client_side_country, locale)).then(
    (res) => res.data
  );

export const _GetDiscountsListing = ({ client_side_country, locale, category }: Partial<DiscountListing>) =>
  Axios.get<DiscountListingResponse>(localize(`bookings/discount_listing/`, client_side_country, locale), {
    params: {
      category,
    },
  }).then((res) => res.data);
