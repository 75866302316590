import { COOKIE_LOCALE } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";
import Cookies from "cookies";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PopularSpecialitiesSection } from "TS_components";
import HomeHero from "TS_components/HomeHero";
import { HomeWrapper } from "TS_components/HomePage/HomePage.styled";
import { PopularClinicsSectionTemp } from "TS_components/PopularClinicsSection/AfricaTemp";
import AfricaTemp from "TS_components/PopularDoctorsSection/AfricaTemp";
import { _GetDiscountsData, _GetDiscountsListing } from "TS_Services/Discount";
import { _GetHomePage } from "TS_Services/Home";
import { _GetInsurancesList } from "TS_Services/Insurance";
import { DiscountCategoriesList } from "types/Discount";
import { HomePageData } from "types/Home";
import { wrapper } from "../data/store";
import { DEFAULT_LOCALE, getCountryFromRequestUrl, Mixpanel, TrackGtm } from "../utils";

const PopularClinicsSection = dynamic(() => import("TS_components/PopularClinicsSection"), {
  ssr: false,
});

const BlogSection = dynamic(() => import("TS_components/BlogsSection"), {
  ssr: false,
});

const DiscountsSection = dynamic(() => import("TS_components/DiscountsSection"), {
  ssr: false,
});

const InsuranceSection = dynamic(() => import("TS_components/InsuranceSection/index"), {
  ssr: false,
});

const PopluarDoctorsSection = dynamic(() => import("TS_components/PopularDoctorsSection"), {
  ssr: false,
});

type Props = {
  homeData: HomePageData;
};

export default function LandingPage({ homeData }: Props) {
  const { client_side_country } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const initData = useSelector(({ InitialData }: any) => InitialData.initData);
  const { locale } = useRouter();
  const [discountCategoriesData, setDiscountCategoriesData] = useState<DiscountCategoriesList<string>>({});
  const { data } = useQuery(
    ["home", client_side_country, locale],
    () =>
      _GetHomePage({
        country: client_side_country,
        locale,
      }),
    {
      initialData: homeData,
    }
  );

  const { data: insuranceData } = useQuery(
    ["insurance-data", client_side_country, locale],
    () =>
      _GetInsurancesList({
        client_side_country,
        locale,
      }),
    {
      initialData: homeData,
    }
  );

  const getDiscountCategoriesData = async (discounts) => {
    let categoriesData = {};
    if (discounts) {
      for (const category of discounts["discount_categories"]) {
        let res = await _GetDiscountsListing({
          client_side_country,
          locale,
          category: category.slug,
        });
        categoriesData[category.name] = res["discounts"]?.length && res["discounts"];
      }
    }

    if (!Object.keys(categoriesData).length) {
      Mixpanel.track("Home Page | Page Loaded w/o Discounts | Event", {
        Locale: client_side_country || "qatar",
        Language: locale.toUpperCase(),
      });
    }

    setDiscountCategoriesData(categoriesData);
  };

  const { data: discounts } = useQuery(
    ["discount", client_side_country, locale],
    () =>
      _GetDiscountsData({
        client_side_country,
        locale,
      }),
    {
      retry: false,
      onSettled(data, error) {
        getDiscountCategoriesData(data);
      },
    }
  );

  useEffect(() => {
    if (initData) {
      Mixpanel.track("Homepage | Page Loaded | Event ");
      TrackGtm(initData, {
        pageCategory: "Home Page",
        Locale: client_side_country || "qatar",
        Language: locale.toUpperCase(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData]);

  return (
    <HomeWrapper>
      <HomeHero data={data} discounts={discounts} discountCategories={discountCategoriesData} />
      <PopularSpecialitiesSection />
      {["qatar", "uae", "sa"].includes(client_side_country || "qatar") ? <PopluarDoctorsSection /> : <AfricaTemp />}
      {["qatar", "uae", "sa"].includes(client_side_country || "qatar") ? (
        <PopularClinicsSection />
      ) : (
        <PopularClinicsSectionTemp />
      )}
      <DiscountsSection discounts={discounts} discountCategoriesData={discountCategoriesData} />
      {insuranceData && <InsuranceSection insurances={insuranceData?.insurance || []} />}
      {/* <HomeTestimonials /> */}
      <BlogSection homeData={homeData} />
      {/* <DownloadAppSection /> */}
    </HomeWrapper>
  );
}

const _GetHomePageRedirection = (clientIp: string) =>
  fetch(process.env.NEXT_PUBLIC_BACKEND_BASE_URL + "?redirect=1", {
    method: "GET",
    headers: {
      Accept: "application/json, text/plain, */*",
      "x-requested-with": "XMLHttpRequest",
      "Client-IP": clientIp,
    },
  }).then((res) => res.json());

export const getServerSideProps = wrapper.getServerSideProps(async (ctx) => {
  const { req, res, locale }: any = ctx;
  res.setHeader("Cache-Control", "public, s-maxage=10, stale-while-revalidate=59");
  // let uae = '86.97.69.35';
  // let Nigeria = '197.211.63.107';
  // let Egypt = '41.32.0.0';

  let Qatar = "197.211.63.107";
  let clientIp =
    process.env.NODE_ENV === "development"
      ? Qatar
      : req.ip || req?.headers["x-forwarded-for"]?.split(",")[0] || req?.headers["x-real-ip"]?.split(",")[0];

  const cookies = new Cookies(req, res);
  const cookieCountry = cookies.get(COOKIE_LOCALE);
  const inRootPath = req.url === "/";

  if (inRootPath) {
    if (typeof cookieCountry === "undefined") {
      if (!clientIp) {
        console.log("===IP_LOCALE=== , !!! Failed To get Client IP !!!", req.headers);
      }
      const redirectionData = await _GetHomePageRedirection(clientIp);
      console.log("===IP_LOCALE=== , The Response Data ", redirectionData.redirect);

      if (redirectionData?.redirect && redirectionData?.redirect !== DEFAULT_LOCALE) {
        cookies.set(COOKIE_LOCALE, redirectionData?.redirect, {
          httpOnly: true,
        });
        return {
          redirect: {
            permenant: false,
            destination: "/" + redirectionData?.redirect + "/",
          },
        };
      }
    }
  }

  let tempCountry = null; // "qatar"
  tempCountry = getCountryFromRequestUrl(req.url || req.headers.referer);
  const homeData = await _GetHomePage({
    country: tempCountry || DEFAULT_LOCALE,
    locale: locale,
  });

  return {
    props: {
      homeData,
    },
  };
});
