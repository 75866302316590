import { DiscountTypeLinks, DiscountTypes } from "@/components/Discounts";
import { Mixpanel, localize } from "@/utils";
import useTranslation from "intl/useTranslation";
import { useRouter } from "next/router";
import { Dispatch, Fragment, MouseEventHandler, SetStateAction, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { DiscountCategoriesList, DiscountDataResponse } from "types/Discount";
import { HomePageData } from "types/Home";
import CommonDropdown from "./CommonDropdown";
import runToast from "./CommonToast";
import CountriesDropdown, { CountryValue } from "./CountriesDropdown/";
import { SelectButton } from "./CountriesDropdown/CountriesDropdown.styled";
import {
  FormDropdownWrapper,
  HeroActionsWrapper,
  HeroCTA,
  SpecialtyWrapper,
  VisitType,
  VisitTypesWrapper,
} from "./HomeHero.styled";
import ChevronDownIcon from "./icons/ChevronDownIcon";
import ClinicIcon from "./icons/ClinicIcon";
import DiscountsIcon from "./icons/DiscountsIcon";
import PinIcon from "./icons/PinIcon";
import VideoIcon from "./icons/VideoIcon";

type Props = {
  data: HomePageData;
  discounts: DiscountDataResponse;
  discountCategories: DiscountCategoriesList<string>;
  isSelectorsHidden: boolean;
  setIsSelectorsHidden: Dispatch<SetStateAction<boolean>>;
};

const getVisitType = (type: "clinic" | "video" | "discounts") =>
  type === "clinic" ? "Clinic" : type === "video" ? "Video" : "Discounts";

export default function HeroActions({
  data,
  discounts,
  discountCategories,
  isSelectorsHidden,
  setIsSelectorsHidden,
}: Props) {
  const theme = useTheme() as any;
  const { t, locale } = useTranslation();
  const { locale_name, client_side_country } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const disableVideoVisits = ["ng"].includes(client_side_country);
  const [selectedType, setSelectedType] = useState<"clinic" | "video" | "discounts">("clinic");
  const [countryValue, setCountryValue] = useState<CountryValue>();
  const [selectedSpecialty, setSelectedSpecialty] = useState<string>(null);
  const [selectedDiscount, setSelectedDiscount] = useState<string>("");
  const [isErrorMsg, setIsErrorMsg] = useState(false);
  const { push } = useRouter();
  const discountsOptions = useMemo(() => {
    const baseDiscountsOptions = discounts
      ? Object.keys(DiscountTypes)
          .map((key) => {
            if (discounts[DiscountTypes[key]]?.length) {
              return {
                name: t(key) || key,
                name_en: key,
                value: "type=" + DiscountTypeLinks[key],
              };
            }
          })
          .filter(Boolean)
      : [];
    const discountsCategoriesOptions = discountCategories
      ? Object.keys(discountCategories).map((key) => ({
          name: t(key) || key,
          name_en: key,
          value: "category=" + discounts["discount_categories"].filter((category) => category.name === key)[0]?.slug,
        }))
      : [];
    return [...baseDiscountsOptions, ...discountsCategoriesOptions];
  }, [discounts, discountCategories]);

  useEffect(() => {
    setCountryValue({});
    setIsErrorMsg(false);
    setSelectedDiscount("");
    toast.dismiss();
  }, [selectedType]);

  const submitDiscount: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const cityInEnglish = countryValue.city
      ? countryValue.city === "All Cities"
        ? "All Cities"
        : data.cities.find((city) => city.value === countryValue.city)?.name_en
      : null;
    const areaInEnglish = countryValue.area
      ? countryValue.area === "All Areas"
        ? "All Areas"
        : data.areas
        ? data.areas.find((area) => area.value === countryValue.area)?.name_en
        : data.cities
            .find((city) => city.value === countryValue.city)
            .areas.find((area) => area.value === countryValue.area)?.name_en
      : "All Areas";
    Mixpanel.track("Homepage | Search Clicked | Button", {
      "Visit Type": "Home",
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
      "Discount Category Name":
        selectedDiscount === ""
          ? "All Categories"
          : discountsOptions.find((dis) => dis.value === selectedDiscount).name_en,
      "City Name": cityInEnglish,
      "Area Name": areaInEnglish,
    });
    if (selectedDiscount === "") {
      push(localize(`/discounts/`, client_side_country, locale));
    } else {
      push(localize(`/discounts/listings?${selectedDiscount}`, client_side_country, locale));
    }
  };
  const submitClinicOrVideo: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (!selectedSpecialty) {
      setIsErrorMsg(true);
      runToast.error({
        id: "speciality-err-toast",
        title: t("Speciality is required"),
        desc: t("You have to select a speciality before searching"),
        onCloseCallback: () => setIsErrorMsg(false),
      });
      Mixpanel.track("Homepage | Search w/o Speciality Error | Event ", {
        Locale: client_side_country || "qatar",
        Language: locale.toUpperCase(),
      });
      return;
    }

    const specialtySlug = selectedSpecialty ? selectedSpecialty.replaceAll("-", "+") + "-" : "";
    const theCitySlug =
      countryValue && countryValue.city ? (countryValue.city === "All Cities" ? null : countryValue.city) : null;
    const theAreaSlug =
      countryValue && countryValue.area ? (countryValue.area === "All Areas" ? null : countryValue.area) : null;
    if (selectedType === "clinic") {
      const citySlug = theCitySlug ? theCitySlug.replaceAll("-", "+") + "-" : "";
      const areaSlug = theAreaSlug ? theAreaSlug.replaceAll("-", "+") + "-" : "";
      let query = (specialtySlug + citySlug + areaSlug).slice(0, -1);
      push(localize(`/doctors/${query}`, client_side_country, locale));
    } else if (selectedType === "video") {
      push(localize(`/doctors/${specialtySlug.slice(0, -1)}/?video=true`, client_side_country, locale));
    }

    const cityInEnglish = countryValue.city
      ? countryValue.city === "All Cities"
        ? "All Cities"
        : data.cities.find((city) => city.value === countryValue.city)?.name_en
      : null;
    const areaInEnglish = countryValue.area
      ? countryValue.area === "All Areas"
        ? "All Areas"
        : data.areas
        ? data.areas.find((area) => area.value === countryValue.area)?.name_en
        : data.cities
            .find((city) => city.value === countryValue.city)
            .areas.find((area) => area.value === countryValue.area)?.name_en
      : "All Areas";
    const speciality_name_en = data.specs
      .map(({ friendly_name, ...spec }) => ({
        ...spec,
        name: friendly_name,
      }))
      ?.find((speciality) => selectedSpecialty === speciality.value).name_en;
    Mixpanel.track("Homepage | Search Clicked | Button", {
      "Visit Type": getVisitType(selectedType),
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
      "Discount Category Name": selectedDiscount === "" ? "All Categories" : selectedDiscount,
      "Speciality Name": speciality_name_en,
      "City Name": cityInEnglish,
      "Area Name": areaInEnglish,
    });
  };

  const onSelectType = (type: "clinic" | "video" | "discounts") => {
    setSelectedType(type);
    Mixpanel.track(`Homepage | Select ${getVisitType(type)} on Hero | Button`, {
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
    });
  };

  const onToggleSpecialtyDropdown = (isOpened: boolean) => {
    Mixpanel.track(`Homepage | Speciality ${isOpened ? "Opened" : "Closed"} | Dropdown`, {
      "Visit Type": getVisitType(selectedType),
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
    });
  };

  const onSearchSpecialty = (searchTerm: string) => {
    Mixpanel.track(`Homepage | Search Speciality Started | Dropdown`, {
      "Visit Type": getVisitType(selectedType),
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
    });
  };

  const onSelectSpecialty = (spec: string) => {
    setSelectedSpecialty(spec);
    const name_en = data.specs
      .map(({ friendly_name, ...spec }) => ({
        ...spec,
        name: friendly_name,
      }))
      ?.find((speciality) => spec === speciality.value).name_en;
    Mixpanel.track(`Homepage | Speciality Selected | Dropdown`, {
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
      "Speciality Name": name_en || spec,
      "Visit Type": getVisitType(selectedType),
    });
  };

  const onToggleDiscountsDropdown = (isOpened: boolean) => {
    Mixpanel.track(`Homepage | Discount Category ${isOpened ? "Opened" : "Closed"} | Dropdown`, {
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
    });
  };

  const onSearchDiscounts = (searchTerm: string) => {
    Mixpanel.track(`Homepage | Search Discount Category Started | Dropdown`, {
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
    });
  };

  const onSelectDiscount = (slug: string) => {
    const theSelectedDiscout = discountsOptions.find((dis) => dis.value === slug);
    setSelectedDiscount(slug);
    Mixpanel.track(`Homepage | Discount Category Selected | Dropdown`, {
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
      "Discount Category Name": theSelectedDiscout?.name_en || "All Categories",
    });
  };

  const onToggleCountryDropdown = (isOpened: boolean, type: "City" | "Area") => {
    Mixpanel.track(`Homepage | ${type} ${isOpened ? "Opened" : "Closed"} | Dropdown`, {
      "Visit Type": getVisitType(selectedType),
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
    });
  };

  const onSearchCountry = (searchTerm: string, type: "City" | "Area") => {
    Mixpanel.track(`Homepage | Search ${type} Started | Dropdown`, {
      "Visit Type": getVisitType(selectedType),
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
    });
  };

  const onSelectCountryData = (val: CountryValue, type: "City" | "Area") => {
    const cityInEnglish = val.city
      ? val.city === "All Cities"
        ? "All Cities"
        : data.cities.find((city) => city.value === val.city)?.name_en
      : null;
    const areaInEnglish = val.area
      ? val.area === "All Areas"
        ? "All Areas"
        : data.areas
        ? data.areas.find((area) => area.value === val.area)?.name_en
        : data.cities.find((city) => city.value === val.city).areas.find((area) => area.value === val.area)?.name_en
      : "All Areas";
    Mixpanel.track(`Homepage | ${type} Selected | Dropdown`, {
      "Visit Type": getVisitType(selectedType),
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
      "City Name": cityInEnglish,
      "Area Name": areaInEnglish,
    });
  };

  useEffect(() => {
    setIsSelectorsHidden(disableVideoVisits && !Boolean(discountsOptions.length));
  }, [disableVideoVisits, discountsOptions]);

  return (
    <HeroActionsWrapper>
      {!isSelectorsHidden && (
        <VisitTypesWrapper>
          <VisitType
            isSelected={selectedType === "clinic"}
            onClick={() => onSelectType("clinic")}
            data-cy="type-clinic"
          >
            <ClinicIcon fill={selectedType === "clinic" ? theme.colors.white : theme.colors.primaryPurple} />
            {t("Clinic Visit")}
          </VisitType>
          {!disableVideoVisits && (
            <VisitType isSelected={selectedType === "video"} onClick={() => onSelectType("video")} data-cy="type-video">
              <VideoIcon fill={selectedType === "video" ? theme.colors.white : theme.colors.primaryPurple} />
              {t("Video Visit")}
            </VisitType>
          )}
          {Boolean(discountsOptions.length) && (
            <VisitType
              isSelected={selectedType === "discounts"}
              onClick={() => onSelectType("discounts")}
              data-cy="type-discounts"
            >
              <DiscountsIcon fill={selectedType === "discounts" ? theme.colors.white : theme.colors.primaryPurple} />
              {t("Discounts")}
            </VisitType>
          )}
        </VisitTypesWrapper>
      )}
      <FormDropdownWrapper isSelectorsHidden={isSelectorsHidden}>
        {(selectedType === "clinic" || selectedType === "video") && (
          <Fragment>
            {selectedType === "clinic" && (
              <CountriesDropdown
                areas={data.areas}
                cities={data.cities}
                placeholder={locale_name}
                value={countryValue}
                setValue={setCountryValue}
                onToggleDropdown={onToggleCountryDropdown}
                onSelectOption={onSelectCountryData}
                startSearchAnalytics={onSearchCountry}
                triggerer={({ labelName, ...props }) => (
                  <SelectButton {...props}>
                    <PinIcon />
                    <span>{labelName || locale_name}</span>
                    <ChevronDownIcon styleProps={{ margin: 0 }} />
                  </SelectButton>
                )}
              />
            )}
            <SpecialtyWrapper>
              <CommonDropdown
                options={data.specs.map(({ friendly_name, ...spec }) => ({
                  ...spec,
                  name: friendly_name,
                }))}
                title={t("Speciality")}
                placeholder={t("Select Speciality")}
                value={selectedSpecialty}
                setValue={onSelectSpecialty}
                toggleOpenCallback={onToggleSpecialtyDropdown}
                startSearchAnalytics={onSearchSpecialty}
                searchInputPlaceholder={t("Search Specialities")}
                isError={isErrorMsg}
                label="Specialty"
              />
              <HeroCTA onClick={submitClinicOrVideo} data-cy="hero-cta">
                {t("Search")}
              </HeroCTA>
            </SpecialtyWrapper>
          </Fragment>
        )}
        {selectedType === "discounts" && (
          <SpecialtyWrapper>
            <CommonDropdown
              options={discountsOptions}
              title={t("Discount Category")}
              placeholder={t("")}
              value={selectedDiscount}
              setValue={onSelectDiscount}
              searchInputPlaceholder={t("Search Discount Categories")}
              selectAllOption={{
                name: t("All Categories"),
                name_en: "All Categories",
                value: "",
              }}
              toggleOpenCallback={onToggleDiscountsDropdown}
              startSearchAnalytics={onSearchDiscounts}
              label="Discounts"
            />
            <HeroCTA onClick={submitDiscount} data-cy="hero-cta">
              {t("Search")}
            </HeroCTA>
          </SpecialtyWrapper>
        )}
      </FormDropdownWrapper>
    </HeroActionsWrapper>
  );
}
