import { localize } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import useTranslation from "intl/useTranslation";
import { Mixpanel } from "lib/Mixpanel";
import Link from "next/link";
import { useRouter } from "next/router";
import { specPaths } from "public/assets/homePageData";
import { useState } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { SliderViewAllArrow } from "TS_components/common/SilderViewAllArrow";
import { SectionTitle } from "TS_components/HomePage/HomePage.styled";
import PopularClinicCard from "TS_components/PopularClinicCard";
import { LeftArrow, RightArrow } from "TS_components/PopularDoctorsSection";

import {
  CardsContainer,
  CarouselHeader,
  CarouselWrapper,
  Section,
} from "TS_components/PopularDoctorsSection/PopularDoctorsSection.styles";
import { _GetPopularClinics } from "TS_Services/Home";
import Skeleton from "./CardSkeleton";
import TempFilters from "./TempFilters";

export const PopularClinicsSectionTemp = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const specPath: string = specPaths[0][`name_${locale}`];
  const defaultSlug: string = specPath;
  const [clinicSlug, setClinicSlug] = useState<string>(defaultSlug);
  const { client_side_country } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const [activeFilter, setActiveFilter] = useState("OBGYN");

  const handleFilterChange = (filter) => {
    if (!filter) return;
    const specObject = specPaths.find((spec) => {
      if (filter === "OBGYN") return spec.text === "Obstetricians & Gynecologists";
      if (filter === "Family Medicine") return spec.text === "General Doctors";
      return spec.text === filter;
    });
    const slug = specObject ? specObject[`name_${locale}`] : defaultSlug;
    const eventName = "Homepage | Popular Clinic Speciality | Quick Filter";
    const eventProps = {
      "Speciality Name": filter,
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
    };
    setActiveFilter(filter);
    setClinicSlug(slug);
    Mixpanel.track(eventName, eventProps);
  };

  const viewAllClickEvent = () => {
    const eventName = "Homepage | View All Clinics | Arrow Button";
    const eventProps = {
      "Speciality Name": activeFilter,
      Language: locale.toUpperCase(),
      Locale: client_side_country || "qatar",
    };

    Mixpanel.track(eventName, eventProps);
  };

  const { data: OBGYN } = useQuery(
    ["OBGYN clinics", client_side_country, locale],
    () =>
      _GetPopularClinics({
        country: client_side_country,
        locale: locale,
        slug: specPaths[0][`name_${locale}`],
      }),
    {}
  );

  const { data: pediatrician } = useQuery(
    ["pediatrician clinics", client_side_country, locale],
    () =>
      _GetPopularClinics({
        country: client_side_country,
        locale: locale,
        slug: specPaths[1][`name_${locale}`],
      }),
    {}
  );

  const { data: generalDoctors } = useQuery(
    ["generalDoctors clinics", client_side_country, locale],
    () =>
      _GetPopularClinics({
        country: client_side_country,
        locale: locale,
        slug: specPaths[2][`name_${locale}`],
      }),
    {}
  );

  const { data: generalDentists } = useQuery(
    ["generalDentists clinics", client_side_country, locale],
    () =>
      _GetPopularClinics({
        country: client_side_country,
        locale: locale,
        slug: specPaths[3][`name_${locale}`],
      }),
    {}
  );

  const { data: dermatologist } = useQuery(
    ["dermatologist clinics", client_side_country, locale],
    () =>
      _GetPopularClinics({
        country: client_side_country,
        locale: locale,
        slug: specPaths[4][`name_${locale}`],
      }),
    {}
  );
  const { data: ophthalmologist } = useQuery(
    ["ophthalmologist clinics", client_side_country, locale],
    () =>
      _GetPopularClinics({
        country: client_side_country,
        locale: locale,
        slug: specPaths[5][`name_${locale}`],
      }),
    {}
  );

  const OBGYNData = OBGYN?.listings.length ? OBGYN?.listings : null;
  const pediatricianData = pediatrician?.listings.length ? pediatrician?.listings : null;
  const generalDoctorsData = generalDoctors?.listings.length ? generalDoctors?.listings : null;
  const generalDentistsData = generalDentists?.listings.length ? generalDentists?.listings : null;
  const dermatologistData = dermatologist?.listings.length ? dermatologist?.listings : null;
  const ophthalmologistData = ophthalmologist?.listings.length ? ophthalmologist?.listings : null;

  console;
  const filterMap = {
    OBGYN: OBGYNData,
    Pediatricians: pediatricianData,
    "Family Medicine": generalDoctorsData,
    "General Dentists": generalDentistsData,
    "Skin Doctors": dermatologistData,
    "Eye Doctors": ophthalmologistData,
  };

  const filterNames = [
    "OBGYN",
    "Pediatricians",
    "Family Medicine",
    "GeneralDentists",
    "Skin Doctors",
    "Eye Doctors",
  ].filter((element) => {
    return filterMap[element];
  });

  // do not render section if there is no data
  const dataCheck = Object.values(filterMap).some((element) => element);
  if (!dataCheck) return null;

  return (
    <Container>
      <Row>
        <Col col={12}>
          <Section>
            <SectionTitle>{t("Popular Clinics")}</SectionTitle>
            <TempFilters
              filters={filterNames}
              callBack={handleFilterChange}
              activeFilter={activeFilter || "OBGYN"}
            />{" "}
            <CarouselWrapper>
              {filterMap[activeFilter] ? (
                <ScrollMenu
                  RTL={locale === "ar"}
                  Header={() => (
                    <CarouselHeader>
                      <LeftArrow /> <RightArrow />
                    </CarouselHeader>
                  )}
                  scrollContainerClassName="scroll-container"
                  separatorClassName="scroll-separator"
                  itemClassName="scroll-item"
                >
                  {filterMap[activeFilter] &&
                    filterMap[activeFilter]
                      .slice(0, 4)
                      .map((clinic) => <PopularClinicCard speciality={activeFilter} clinicData={clinic} />)}
                  {filterMap[activeFilter] && filterMap[activeFilter].length > 4 && (
                    <Link href={`/${localize(`clinics/${clinicSlug}`, client_side_country, locale)}`}>
                      <a className="view-all-btn">
                        <SliderViewAllArrow
                          onClick={viewAllClickEvent}
                          text={`${t("View All Clinics")}`}
                          direction={locale}
                        />
                      </a>
                    </Link>
                  )}
                </ScrollMenu>
              ) : (
                <CardsContainer>
                  {[1, 2, 3].map(() => (
                    <Skeleton rtl={locale === "ar"} />
                  ))}
                </CardsContainer>
              )}
            </CarouselWrapper>
          </Section>
        </Col>
      </Row>
    </Container>
  );
};
