import { getClinicImageUrl } from "helpers/images";
import useTranslation from "intl/useTranslation";
import { Mixpanel } from "lib/Mixpanel";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import ClinicIcon from "TS_components/ClinicIcon";
import RatingWithHearts from "TS_components/RatingWithHearts";
import { CardInfoComponent, CategoryInfoComponent } from "./PopularClinicCard.parts";
import {
  AddressIcon,
  AreaIcon,
  CardGrouping,
  CardLink,
  ClinicName,
  Image,
  ImageContainer,
  ProfileLink,
  SpecialityIcon,
} from "./PopularClinicCard.styles";

const PopularClinicCard = ({ clinicData, speciality }: { clinicData: any; speciality: string }) => {
  const { t } = useTranslation();
  const imageUrl = getClinicImageUrl(clinicData.picture, 200, 350, 95) || "/images/default_hospital.png";
  const { client_side_country } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const { locale: language } = useRouter();

  const localizedLink = client_side_country
    ? `/${client_side_country}/clinic/${clinicData.slug_en}`
    : `/clinic/${clinicData.slug_en}`;

  const visitClinicProfileEvent = (elementName: "Card" | "Button") => {
    const eventName =
      elementName === "Card" ? "Clinic Card | Card Clicked | Card" : "Clinic Card | View Profile Clicked | Button";
    const eventProps = {
      Language: language.toUpperCase(),
      Locale: client_side_country || "qatar",
      "Speciality Name": speciality,
    };

    Mixpanel.track(eventName, eventProps);
  };
  // memoizing the card is important for 2 reasons
  // 1- as we drag to slide the components will rerender on each pixel shift
  // 2- the categories component performs a loop inside the original loop to render this component, so quadratic complexity.
  // Running the quadratic operation on every rerender is going to be very expensive.
  return useMemo(
    () => (
      <Link href={localizedLink} locale={language}>
        <CardLink onClick={() => visitClinicProfileEvent("Card")}>
          <CardGrouping noMargin>
            <ImageContainer>
              <Image src={imageUrl} width={300} height={127} />
            </ImageContainer>
            <RatingWithHearts rating={clinicData.rating} />
            <ClinicName>{clinicData.name}</ClinicName>
          </CardGrouping>
          <CardGrouping noMargin>
            {clinicData.specializations && (
              <CategoryInfoComponent
                Icon={SpecialityIcon}
                category={t("Specialities")}
                value={clinicData.specializations}
              />
            )}
            {clinicData.area && <CardInfoComponent Icon={AreaIcon} category={t("Area")} value={clinicData.area} />}
            {clinicData.address && (
              <CardInfoComponent Icon={AddressIcon} category={t("Address")} value={clinicData.address} />
            )}
          </CardGrouping>
          <Link href={localizedLink} locale={language}>
            <ProfileLink
              data-cy="view-clinic"
              onClick={(e) => {
                e.stopPropagation();
                visitClinicProfileEvent("Button");
              }}
            >
              <ClinicIcon /> {t("View Clinic")}
            </ProfileLink>
          </Link>
        </CardLink>
      </Link>
    ),
    [clinicData.slug]
  );
};

export default PopularClinicCard;
