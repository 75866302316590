type Props = {
  fill?: string;
};

export default function DiscountsIcon({ fill = '#2A3390' }: Props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2879 1.71209C14.654 2.0782 14.654 2.6718 14.2879 3.03791L3.03791 14.2879C2.6718 14.654 2.0782 14.654 1.71209 14.2879C1.34597 13.9218 1.34597 13.3282 1.71209 12.9621L12.9621 1.71209C13.3282 1.34597 13.9218 1.34597 14.2879 1.71209Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.78125 7.0625C5.59343 7.0625 7.0625 5.59343 7.0625 3.78125C7.0625 1.96907 5.59343 0.5 3.78125 0.5C1.96907 0.5 0.5 1.96907 0.5 3.78125C0.5 5.59343 1.96907 7.0625 3.78125 7.0625ZM3.78125 5.1875C4.5579 5.1875 5.1875 4.5579 5.1875 3.78125C5.1875 3.0046 4.5579 2.375 3.78125 2.375C3.0046 2.375 2.375 3.0046 2.375 3.78125C2.375 4.5579 3.0046 5.1875 3.78125 5.1875Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2187 15.5C14.0309 15.5 15.5 14.0309 15.5 12.2187C15.5 10.4066 14.0309 8.9375 12.2187 8.9375C10.4066 8.9375 8.9375 10.4066 8.9375 12.2187C8.9375 14.0309 10.4066 15.5 12.2187 15.5ZM12.2187 13.625C12.9954 13.625 13.625 12.9954 13.625 12.2187C13.625 11.4421 12.9954 10.8125 12.2187 10.8125C11.4421 10.8125 10.8125 11.4421 10.8125 12.2187C10.8125 12.9954 11.4421 13.625 12.2187 13.625Z"
        fill={fill}
      />
    </svg>
  );
}
