/**
 * this file extracts bits of the main component where it makes sense
 * this is done for readability and maintainability
 */
import { FC } from 'react';
import { ClinicInformationWrapper, InformationDescription } from './PopularClinicCard.styles';
import { turncateSpecs } from './PopularClinicCard.utils';
// will render the information about area/address in the clinic card component
export const CardInfoComponent = ({ category, value, Icon }: { category: string; value: string; Icon?: FC }) => {
  return (
    <ClinicInformationWrapper>
      <InformationDescription>
        <Icon /> <strong>{category}</strong>: {value}
      </InformationDescription>
    </ClinicInformationWrapper>
  );
};

// similar to the component above, but instead of rendering a string directly, it works with an array of string as its "value" prop
export const CategoryInfoComponent = ({
  category,
  value,
  Icon,
}: {
  category: string;
  value: Array<{ name: string }>;
  Icon?: FC;
}) => {
  const specializationString = turncateSpecs(value);
  return (
    <ClinicInformationWrapper>
      <InformationDescription>
        <Icon /> <strong>{category}</strong>: {specializationString}
      </InformationDescription>
    </ClinicInformationWrapper>
  );
};
