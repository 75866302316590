import { useQuery } from "@tanstack/react-query";
import useTranslation from "intl/useTranslation";
import { Mixpanel } from "lib/Mixpanel";
import Link from "next/link";
import { useRouter } from "next/router";
import { specPaths } from "public/assets/homePageData";
import { useState } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { SliderViewAllArrow } from "TS_components/common/SilderViewAllArrow";
import { SectionTitle } from "TS_components/HomePage/HomePage.styled";
import PopularDoctorCard from "TS_components/PopularDoctorCard";
import { _GetPopularDoctors } from "TS_Services/Doctors";
import { localize } from "utils/url";
import { LeftArrow, RightArrow } from ".";
import { CarouselHeader, CarouselWrapper, Section } from "./PopularDoctorsSection.styles";
import TempFilters from "./TempFilters";

const PopularDoctorsSection = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { client_side_country } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const [activeFilter, setActiveFilter] = useState<string>("OBGYN");
  const replaceDashesWithPluses = (str) => str.replace(/-/g, "+");

  const viewAllClickEvent = () => {
    const eventName = "Homepage | View All Doctors | Arrow Button";
    const eventProps = {
      "Speciality Name": activeFilter,
      Language: locale.toUpperCase(),
      Locale: client_side_country || "qatar",
    };

    Mixpanel.track(eventName, eventProps);
  };

  const { data: OBGYN } = useQuery(["OBGYN", client_side_country, locale], () =>
    _GetPopularDoctors({
      country: client_side_country,
      locale: locale,
      slug: specPaths[0][`name_${locale}`],
      filters: { clinic: false, video: true, sortBy: "default", get_filters: false, featured: true },
    })
  );

  const { data: pediatrician } = useQuery(
    ["pediatrician", client_side_country, locale],
    () =>
      _GetPopularDoctors({
        country: client_side_country,
        locale: locale,
        slug: specPaths[1][`name_${locale}`],
        filters: { clinic: false, video: true, sortBy: "default", get_filters: false, featured: true },
      }),
    {}
  );

  const { data: generalDoctors } = useQuery(
    ["generalDoctors", client_side_country, locale],
    () =>
      _GetPopularDoctors({
        country: client_side_country,
        locale: locale,
        slug: specPaths[2][`name_${locale}`],
        filters: { clinic: false, video: true, sortBy: "default", get_filters: true, featured: true },
      }),
    {}
  );

  const { data: generalDentists } = useQuery(
    ["generalDentists", client_side_country, locale],
    () =>
      _GetPopularDoctors({
        country: client_side_country,
        locale: locale,
        slug: specPaths[3][`name_${locale}`],
        filters: { clinic: false, video: true, sortBy: "default", get_filters: true, featured: true },
      }),
    {}
  );

  const { data: dermatologist } = useQuery(
    ["dermatologist", client_side_country, locale],
    () =>
      _GetPopularDoctors({
        country: client_side_country,
        locale: locale,
        slug: specPaths[4][`name_${locale}`],
        filters: { clinic: false, video: true, sortBy: "default", get_filters: true, featured: true },
      }),
    {}
  );
  const { data: ophthalmologist } = useQuery(
    ["ophthalmologist", client_side_country, locale],
    () =>
      _GetPopularDoctors({
        country: client_side_country,
        locale: locale,
        slug: specPaths[5][`name_${locale}`],
        filters: { clinic: false, video: true, sortBy: "default", get_filters: true, featured: true },
      }),
    {}
  );

  const handleFilterChange = (filter) => {
    if (!filter) return;
    // todo: add mixpanel
    // Mixpanel.track(eventName, eventProps);
    const eventName = "Homepage | Featured Doctor Speciality | Quick Filter";
    const eventProps = {
      "Speciality Name": filter,
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
    };
    setActiveFilter(filter);
    Mixpanel.track(eventName, eventProps);
  };

  const filterList = [OBGYN, pediatrician, generalDoctors, generalDentists, dermatologist, ophthalmologist].filter(
    Boolean
  );

  const filterNames = filterList.map((filter) => {
    return filter.listings[0].specialization === "Obstetrician and Gynecologist"
      ? "OBGYN"
      : filter.listings[0].specialization;
  });

  const currentFilter = filterList.find((filter) => filter.listings[0].specialization === activeFilter);

  const filterMap = filterNames.reduce((obj, key, index) => {
    return { ...obj, [key]: filterList[index]?.listings };
  }, {});

  // do not render section if there is no data
  const dataCheck = Object.values(filterMap).some((element) => element);
  if (!dataCheck) return null;

  return (
    <Container>
      <Row>
        <Col col={12}>
          <Section>
            <SectionTitle>{t("Featured Doctors")}</SectionTitle>
            <TempFilters
              filters={filterNames}
              callBack={handleFilterChange}
              activeFilter={currentFilter?.listings[0].specialization || "OBGYN"}
            />
            <CarouselWrapper>
              {filterMap[activeFilter] && (
                <ScrollMenu
                  RTL={locale === "ar"}
                  Header={() => (
                    <CarouselHeader>
                      <LeftArrow /> <RightArrow />
                    </CarouselHeader>
                  )}
                  scrollContainerClassName="scroll-container"
                  separatorClassName="scroll-separator"
                  itemClassName="scroll-item"
                >
                  {filterMap[activeFilter].slice(0, 4).map((listing) => (
                    <PopularDoctorCard doctorData={listing} />
                  ))}
                  {filterMap[activeFilter] && filterMap[activeFilter].length > 4 && (
                    <Link
                      href={`/${localize(
                        `doctors/${replaceDashesWithPluses(filterMap[activeFilter][0].specialization_slug)}`,
                        client_side_country,
                        locale
                      )}`}
                    >
                      <SliderViewAllArrow
                        onClick={viewAllClickEvent}
                        text={`${t("View All Doctors")}`}
                        direction={locale}
                      />
                    </Link>
                  )}
                </ScrollMenu>
              )}
            </CarouselWrapper>
          </Section>
        </Col>
      </Row>
    </Container>
  );
};

export default PopularDoctorsSection;
