type Props = {
  fill?: string;
};

export default function ClinicIcon({ fill = '#FFF' }: Props) {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7178 0.721987L19.4924 6.97992C20.0395 7.38161 20.1658 8.14271 19.766 8.69239C19.5345 9.00951 19.1558 9.19979 18.777 9.19979C18.5245 9.19979 18.272 9.11522 18.0616 8.96723L16.6517 7.97357V14.2738C16.6517 14.9503 16.1046 15.5 15.4313 15.5H4.55242C3.87907 15.5 3.33197 14.9503 3.33197 14.2738V7.99472L1.92214 8.98837C1.37504 9.36892 0.617517 9.24207 0.217714 8.69239C-0.161047 8.14271 -0.0347936 7.38161 0.512305 6.97992L9.30797 0.721987C9.72882 0.426004 10.297 0.426004 10.7178 0.721987ZM12.8429 10.1301C12.9692 10.1301 13.0744 10.0244 13.0744 9.89758V8.31196C13.0744 8.18511 12.9692 8.0794 12.8429 8.0794H11.0333V6.26122C11.0333 6.13437 10.9281 6.02866 10.8018 6.02866H9.22368C9.09742 6.02866 8.99221 6.13437 8.99221 6.26122V8.0794H7.18258C7.05632 8.0794 6.95111 8.18511 6.95111 8.31196V9.89758C6.95111 10.0244 7.05632 10.1301 7.18258 10.1301H8.99221V11.9483C8.99221 12.0752 9.09742 12.1809 9.22368 12.1809H10.8018C10.9281 12.1809 11.0333 12.0752 11.0333 11.9483V10.1301H12.8429Z"
        fill={fill}
      />
    </svg>
  );
}
