import { useMediaQuery } from 'react-responsive';

type Props = {
  fill?: string;
};

export default function HomeHeroStrip({ fill = '#2A3390' }: Props) {
  const isMobile = useMediaQuery({
    maxWidth: 767,
  });

  return isMobile ? (
    <svg width="388" height="79" viewBox="0 0 388 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0.699219H381V74.6992C359.723 56.6992 294.032 30.2992 201.479 52.6992C108.926 75.0992 47.9922 74.1454 6 52.6992V0.699219Z"
        fill="#E9EAF4"
      />
      <path
        d="M2 51.0051C58 76.6983 107.396 73.1408 202.17 51.0051C296.945 28.8694 364.213 58.9107 386 76.6983"
        stroke="#F3BD54"
        strokeWidth="5"
      />
    </svg>
  ) : (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1400 120" preserveAspectRatio="none">
      <path
        d="M1464 34.8398V0H0V89.6652C256.8 33.492 490.667 62.8685 578 80.0784C969 164 1331.33 86.4696 1464 34.8398Z"
        fill="#E9EAF4"
      />
      <path d="M1467 33.5C1029 177.5 723 97.5 559 77.5C395 57.5 237.8 39.1 0 90" stroke="#F3BD54" strokeWidth="6" />
    </svg>
  );
}
