export const turncateSpecs = (specs) => {
  const showMore = specs.length > 4;
  if (!showMore) {
    const specializationString = specs
      .map((spec) => spec.name)
      .join(', ')
      .trim(); // 1, 2, 3, 4, (comma at the end)
    const result = `${specializationString}.`; // 1, 2, 3, 4. (no comma at the end)
    return result;
  }

  const specializationString = specs
    .slice(0, 4)
    .map((spec) => spec.name)
    .join(', '); // 1, 2, 3, 4, (comma at the end)
  let result = specializationString.replace(/ $/, '.');
  const remainingCount = specs.length - 4;
  result += ` (+${remainingCount})`;

  return result;
};
